import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout location={location}>
      <Seo title={`Tag: ${tag}`} />

      <main className="container px-4 max-w-2xl flex-grow">
        <h1 className="text-3xl mb-4">{tagHeader}</h1>
        <div className="my-6">
          <Link to="/tags" className="button py-1 px-2 border-2 rounded tag--button">
            View all tags
          </Link>
        </div>

        <ol className="--post-list-items-list space-y-2">
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug} className="p-2">
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="text-xl font-medium">
                      <Link
                        to={slug}
                        itemProp="url"
                        className=" hover:underline"
                      >
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small><span className="capitalize">{node.fields.collection}</span> - {node.frontmatter.datetext}</small>

                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                      className="font-serif text-base"
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>
      </main>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            collection
            slug
          }
          frontmatter {
            datetext: created(formatString: "LL")
            datetime: created
            title
            description
            tags
          }
        }
      }
    }
  }
`
